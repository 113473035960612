<template>
  <section class="sidebar--content">
    <div v-if="!sidebarContent">
      <div class="sidebar--item" @click="changeContent('rent')">
        <div>{{ $t('general.rent') }}</div>
        <img src="@/assets/img/icons/arrow-right.svg" alt="Arrow Right" />
      </div>
      <!--      <div class="sidebar&#45;&#45;item" @click="changeContent('buy')">-->
      <!--        <div>{{ $t('general.sell') }}</div>-->
      <!--        <img src="@/assets/img/icons/arrow-right.svg" alt="Arrow Right" />-->
      <!--      </div>-->
      <div class="sidebar--item" @click="goToPage('/listing/add')">
        <div style="position: relative; width: fit-content">
          {{ $t('general.addProperty') }}
          <div class="free--marker">{{ $t('general.free') }}!</div>
        </div>
      </div>
      <div class="sidebar--item" @click="goToPage('/help')">
        {{ $t('footer.company.helpCenter') }}
      </div>
      <div class="sidebar--item" @click="goToPage('/corporate-client')">
        {{ $t('footer.company.corporateClient') }}
      </div>
      <a href="https://blog-rentfix.reteknologi.com" target="_blank" class="sidebar--item">
        {{ $t('footer.company.blog') }}
      </a>
      <!--      <div class="sidebar&#45;&#45;item" @click="changeContent('search')">-->
      <!--        <div>Temukan</div>-->
      <!--        <img src="@/assets/img/icons/arrow-right.svg" />-->
      <!--      </div>-->
    </div>
    <div v-else-if="sidebarContent === 'rent' || sidebarContent === 'buy'">
      <div class="sidebar--item-title">
        {{ $t('general.residential') }}
      </div>
      <div
        class="sidebar--item"
        v-for="(item, index) in propertyTypesResidential"
        :key="`residential-${index}`"
        @click="goToSearch(item)"
      >
        {{ item.name }}
      </div>
      <div class="sidebar--item-title">
        {{ $t('general.commercial') }}
      </div>
      <div
        class="sidebar--item"
        v-for="(item, index) in propertyTypesCommercial"
        :key="`commercial-${index}`"
        @click="goToSearch(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div v-else-if="sidebarContent === 'search'"></div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'sidebar-content',
  data: () => ({
    listingType: 'R',
    propertyTypesResidential: [],
    propertyTypesCommercial: [],
  }),
  computed: {
    ...mapState({
      sidebarContent: (state) => state.global.sidebarContent,
    }),
  },
  methods: {
    changeContent(name) {
      this.$store.commit('global/SET_SIDEBAR_CONTENT', name);
      if (name === 'rent') this.listingType = 'R';
      else if (name === 'buy') this.listingType = 'S';
      this.getPropertyTypesResidential();
      this.getPropertyTypesCommercial();
    },
    async getPropertyTypesResidential() {
      this.propertyTypesResidential = await this.$store.dispatch(
        'v2/masters/getPropertyTypesHeader',
        {
          category_id: 7,
          listing_type: this.listingType,
        },
      );
    },
    async getPropertyTypesCommercial() {
      this.propertyTypesCommercial = await this.$store.dispatch(
        'v2/masters/getPropertyTypesHeader',
        {
          category_id: 8,
          listing_type: this.listingType,
        },
      );
    },
    goToSearch(propertyType) {
      this.$store.commit('global/SET_MAIN_SIDEBAR', false);
      this.$store.commit('global/SET_SIDEBAR_CONTENT', null);
      this.$store.commit('v2/search/SET_LISTING_TYPE', this.listingType);
      this.$store.commit('v2/search/SET_PROPERTY_TYPE_ID', propertyType.id);
      this.$store.commit('v2/search/SET_ACTIVE_PROPERTY_TYPES', [propertyType]);
      this.$store.dispatch('v2/search/goToSearchPage', {
        router: this.$router,
        params: this.$route.params,
      });
    },
    goToPage(route) {
      this.$store.commit('global/SET_MAIN_SIDEBAR', false);
      this.$store.commit('global/SET_SIDEBAR_CONTENT', null);
      this.$router.push(route);
    },
  },
};
</script>
